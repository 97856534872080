<ng-container [ngSwitch]="variant">
  <ng-container *ngSwitchCase="'basic'">
    <ng-container *ngIf="link && !disableLink; else button">
      <a
        [attr.data-ignore-scroll]="ignoreScroll ? true : null"
        [routerLink]="link"
        [fragment]="fragment || null"
        [queryParams]="queryParams"
        routerLinkActive="{{ linkActive ? 'active' : '' }}"
        class="link-button {{ classes }} btn-el"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </ng-container>
    <ng-template #button>
      <button
        class="{{ classes }} btn-el"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'stroked'">
    <ng-container *ngIf="link && !disableLink; else button">
      <a
        [attr.data-ignore-scroll]="ignoreScroll ? true : null"
        [routerLink]="link"
        [fragment]="fragment || null"
        [queryParams]="queryParams"
        routerLinkActive="{{ linkActive ? 'active' : '' }}"
        class="link-button {{ classes }} btn-stroked"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-stroked-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </ng-container>
    <ng-template #button>
      <button
        class="{{ classes }} btn-stroked"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-stroked-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'flat'">
    <ng-container *ngIf="link && !disableLink; else button">
      <a
        [attr.data-ignore-scroll]="ignoreScroll ? true : null"
        [routerLink]="link"
        [fragment]="fragment || null"
        [queryParams]="queryParams"
        routerLinkActive="{{ linkActive ? 'active' : '' }}"
        class="link-button {{ classes }} btn-el"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-flat-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </ng-container>
    <ng-template #button>
      <button
        class="{{ classes }} btn-el"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-flat-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'icon'">
    <ng-container *ngIf="link && !disableLink; else button">
      <a
        [attr.data-ignore-scroll]="ignoreScroll ? true : null"
        [routerLink]="link"
        [fragment]="fragment || null"
        [queryParams]="queryParams"
        routerLinkActive="{{ linkActive ? 'active' : '' }}"
        class="link-button {{ classes }} btn-icon"
        [type]="type"
        [color]="color || '#6A7BA6'"
        [disabled]="disabled"
        mat-icon-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </ng-container>
    <ng-template #button>
      <button
        class="{{ classes }} btn-icon"
        [type]="type"
        [color]="color || '#6A7BA6'"
        [disabled]="disabled"
        mat-icon-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'icon-fab'">
    <ng-container *ngIf="link && !disableLink; else button">
      <a
        [attr.data-ignore-scroll]="ignoreScroll ? true : null"
        [routerLink]="link"
        [fragment]="fragment || null"
        [queryParams]="queryParams"
        routerLinkActive="{{ linkActive ? 'active' : '' }}"
        class="link-button {{ classes }} btn-icon btn-icon-fab"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-fab
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </ng-container>
    <ng-template #button>
      <button
        class="{{ classes }} btn-icon btn-icon-fab"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-fab
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'mini-fab'">
    <ng-container *ngIf="link && !disableLink; else button">
      <a
        [attr.data-ignore-scroll]="ignoreScroll ? true : null"
        [routerLink]="link"
        [fragment]="fragment || null"
        [queryParams]="queryParams"
        routerLinkActive="{{ linkActive ? 'active' : '' }}"
        class="link-button {{ classes }} btn-icon btn-icon-fab"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-mini-fab
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </ng-container>
    <ng-template #button>
      <button
        class="{{ classes }} btn-icon btn-icon-fab"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-mini-fab
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="link && !disableLink; else button">
      <a
        [attr.data-ignore-scroll]="ignoreScroll ? true : null"
        [routerLink]="link"
        [fragment]="fragment || null"
        [queryParams]="queryParams"
        routerLinkActive="{{ linkActive ? 'active' : '' }}"
        class="link-button {{ classes }} btn-el"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-raised-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </ng-container>
    <ng-template #button>
      <button
        class="{{ classes }} btn-el"
        [type]="type"
        [color]="color"
        [disabled]="disabled"
        mat-raised-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
