export enum PostStartupStateEnum {
  Concept = 1,
  Prototype = 2,
  MVP = 3,
  Launch = 4,
  EarlyGrowth = 5,
  Scaling = 6,
  SustainedGrowth = 7
}
// Ідея (Concept Stage) – на цій стадії стартап тільки формується, є ідея, але ще немає реального продукту або послуги.
// Прототип (Prototype Stage) – на цьому етапі стартап створює перший прототип або тестову версію продукту для демонстрації ідеї.
// Мінімально життєздатний продукт (MVP) – створено продукт з базовими функціями, який можна тестувати на ринку для отримання відгуків від користувачів.
// Запуск (Launch Stage) – продукт або послуга офіційно виходить на ринок, стартап починає залучати перших клієнтів.
// Раннє зростання (Early Growth) – стартап починає рости, збільшується кількість клієнтів, тестуються різні стратегії розвитку.
// Масштабування (Scaling) – компанія стабільно росте, розширює свою діяльність, наймає більше працівників, активно залучає інвестиції.
// Стабільне зростання (Sustained Growth) – стартап стає стабільною компанією з постійним доходом і великою базою клієнтів.
// Вихід (Exit) – на цьому етапі компанія може бути продана, злиття з іншою компанією, або відбувається публічне розміщення акцій (IPO).
