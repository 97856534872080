import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Injectable } from '@angular/core';

import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { delay, skip, tap } from 'rxjs/operators';

import { ICompanyType } from '@modules/companies/interfaces';

import { LocaleService } from '@core/services/locale.service';

import {
  LANGUAGE_LEVEL,
  AGENT_ROLES,
  ENGLISH_NOT_REQUIRED
} from '@common/shared/constants';
import {
  CurrencyEnum,
  InvoiceLangEnum,
  PlanTypeEnum,
  WorkTypeGroupEnum
} from '@common/shared/enums';

import { BaseHttpService } from '@client/shared/abstracts';
import { ISelectOption } from '@client/shared/components/select';
import {
  WORK_TYPES_TRANSLATES,
  MODE_WORK_GROUPS,
  CANCEL_INVITE_REASONS,
  CANCEL_REQUEST_REASONS,
  HIRING_STEPS,
  EXPERIENCES,
  EMPLOYEES,
  FILTER_INFORM_INTERVAL,
  STATE_OPTIONS,
  REQ_INV_FILTER,
  JOBS_FILTER,
  COMPANY_REVIEW_CATEGORIES,
  NO_LOADER_PARAM,
  CLOSE_VACANCY_REASONS,
  LOGIC_CONDITIONS,
  READY_TO_WORK,
  REQ_INV_STATE,
  POST_STARTUP_STATE,
  HUB_CATEGORIES,
  POST_MEME_NAV,
  CLOSE_POST_REASONS
} from '@client/shared/constants';
import {
  ICategory,
  IDomain,
  ILanguage,
  IValues,
  IWorkType,
  IWorkTypesGroup
} from '@client/shared/interfaces';
import { mapCategoryGroup } from '@client/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ValuesService extends BaseHttpService {
  workTypes: IWorkType[] = [];
  thisYear = new Date().getFullYear();
  experiencesFrom = [...new Array(40)].map(() => {
    const year = this.thisYear--;
    return {
      id: year,
      value: year
    };
  });
  private values$ = new BehaviorSubject<IValues>(this.createValues());

  constructor(readonly localeService: LocaleService) {
    super('');
    this.loadValues().subscribe();
    this.localeService
      .getLocale()
      .pipe(skip(1), delay(1))
      .subscribe(() => {
        this.rebuildValues();
      });
  }

  getValues(): Observable<IValues> {
    return this.values$.asObservable();
  }

  createValues() {
    return {
      ...this?.values$?.getValue(),
      english: [...LANGUAGE_LEVEL],
      englishMap: {
        ...this.mapValue([...LANGUAGE_LEVEL]),
        [ENGLISH_NOT_REQUIRED]: _('common.englishLevel.notRequired')
      },
      hiringSteps: HIRING_STEPS,
      employees: EMPLOYEES,
      reqInvStatusFilterOptions: REQ_INV_FILTER,
      jobsStatusFilterOptions: JOBS_FILTER,
      employeesMap: this.mapValue([...EMPLOYEES]),
      agentRoles: [...(AGENT_ROLES as any)],
      experiences: EXPERIENCES,
      experiencesMap: this.mapValue([...EXPERIENCES]),
      experiencesFrom: this.experiencesFrom,
      cancelInviteReasons: CANCEL_INVITE_REASONS.filter((el) => !el.disabled),
      cancelInviteReasonsMap: this.mapValue([...CANCEL_INVITE_REASONS]),
      closeVacancyReasons: CLOSE_VACANCY_REASONS.filter((el) => !el.disabled),
      closeVacancyReasonsMap: this.mapValue([...CLOSE_VACANCY_REASONS]),
      closePostReasons: CLOSE_POST_REASONS.filter((el) => !el.disabled),
      closePostReasonsMap: this.mapValue([...CLOSE_POST_REASONS]),
      reqInvState: REQ_INV_STATE.filter((el) => !el.disabled),
      reqInvStateMap: this.mapValue([...REQ_INV_STATE]),
      cancelRequestReasons: CANCEL_REQUEST_REASONS,
      cancelRequestReasonsMap: this.mapValue([...CANCEL_REQUEST_REASONS]),
      filterInformInterval: FILTER_INFORM_INTERVAL,
      filterInformIntervalMap: this.mapValue([...FILTER_INFORM_INTERVAL]),
      postStartupState: POST_STARTUP_STATE,
      postStartupStateMap: this.mapValue([...POST_STARTUP_STATE]),
      stateOptions: STATE_OPTIONS,
      invoiceLanguages: this.getInvoiceLanguages(),
      currencies: this.getCurrencies(),
      plansNameMap: this.getPlanNames(),
      companyReviewCategories: COMPANY_REVIEW_CATEGORIES,
      companyReviewCategoriesMap: this.mapValue([...COMPANY_REVIEW_CATEGORIES]),
      logicConditions: LOGIC_CONDITIONS.filter((el) => !el.disabled),
      logicConditionsMap: this.mapValue([...LOGIC_CONDITIONS]),
      readyToWork: READY_TO_WORK,
      readyToWorkMap: this.mapValue([...READY_TO_WORK]),
      ...this.mapWorkTypes(),
      hubCategories: HUB_CATEGORIES,
      hubCategoriesMap: this.mapValue([...HUB_CATEGORIES]),
      postMemeNav: POST_MEME_NAV,
      ...this.mapWorkTypes()
    };
  }

  loadValues() {
    return forkJoin([
      this.get<ICategory[]>({ [NO_LOADER_PARAM]: true }, 'categories'),
      this.get<IDomain[]>({ [NO_LOADER_PARAM]: true }, 'domains'),
      this.get<ILanguage[]>({ [NO_LOADER_PARAM]: true }, 'languages'),
      this.get<IWorkType[]>({ [NO_LOADER_PARAM]: true }, 'work-types'),
      this.get<ICompanyType[]>(
        { [NO_LOADER_PARAM]: true },
        'companies/company-types'
      ),
      this.get<IWorkType[]>({ [NO_LOADER_PARAM]: true }, 'event-categories'),
      this.get<IWorkType[]>({ [NO_LOADER_PARAM]: true }, 'news-categories'),
      this.get<IWorkType[]>({ [NO_LOADER_PARAM]: true }, 'startup-categories'),
      this.get<IWorkType[]>({ [NO_LOADER_PARAM]: true }, 'meetup-categories')
    ]).pipe(
      tap(
        ([
          categories,
          domains,
          languages,
          workTypes,
          companyTypes,
          postEventCategories,
          postNewsCategories,
          postStartupCategories,
          postMeetupCategories
        ]) => {
          this.workTypes = workTypes.content;
          this.values$.next({
            ...this.createValues(),
            categoriesGroup: mapCategoryGroup(categories.content),
            categories: categories.content,
            categoriesMap: this.mapValue(categories.content),
            domains: domains.content,
            languages: languages.content,
            companyTypes: companyTypes.content,
            postEventCategories: postEventCategories.content,
            postEventCategoriesMap: this.mapValue(postEventCategories.content),
            postNewsCategories: postNewsCategories.content,
            postNewsCategoriesMap: this.mapValue(postNewsCategories.content),
            postStartupCategories: postStartupCategories.content,
            postStartupCategoriesMap: this.mapValue(
              postStartupCategories.content
            ),
            postMeetupCategories: postMeetupCategories.content,
            postMeetupCategoriesMap: this.mapValue(
              postMeetupCategories.content
            ),
            ...this.mapWorkTypes()
          } as IValues);
        }
      )
    );
  }

  private mapValue(data: any[]): any {
    const map = {};
    data.forEach((el) => {
      map[el.id] = el.value;
    });
    return map;
  }

  rebuildValues() {
    this.values$.next({ ...this.createValues() });
  }

  getInvoiceLanguages(): ISelectOption<any>[] {
    return [
      {
        id: InvoiceLangEnum.UK,
        value: 'Українська'
      },
      {
        id: InvoiceLangEnum.EN,
        value: 'English'
      }
    ];
  }

  getCurrencies(): ISelectOption<CurrencyEnum>[] {
    return [
      {
        id: CurrencyEnum.USD,
        value: CurrencyEnum.USD
      },
      {
        id: CurrencyEnum.UAH,
        value: CurrencyEnum.UAH
      }
    ];
  }

  getPlanNames() {
    return {
      [PlanTypeEnum.Basic]: 'Basic',
      [PlanTypeEnum.Standard]: 'Standard',
      [PlanTypeEnum.Premium]: 'Premium'
    };
  }

  private mapWorkTypes(): {
    workTypesGroup: IWorkTypesGroup[];
    workTypes: ISelectOption[];
    workTypesTranslate: { [key: string]: string };
  } {
    const workTypes = this.workTypes || [];
    const getWorkTypeOption = (option: IWorkType) => {
      return {
        ...option,
        valueKey: option.value,
        value: WORK_TYPES_TRANSLATES[option.value]
      };
    };
    const data = {
      [WorkTypeGroupEnum.Time]: {
        group: WorkTypeGroupEnum.Time,
        title: MODE_WORK_GROUPS[WorkTypeGroupEnum.Time],
        options: []
      },
      [WorkTypeGroupEnum.Place]: {
        group: WorkTypeGroupEnum.Place,
        title: MODE_WORK_GROUPS[WorkTypeGroupEnum.Place],
        options: []
      }
    };
    workTypes.forEach((t) => {
      try {
        data[t.group]?.options.push(getWorkTypeOption(t));
      } catch (e) {}
    });
    return {
      workTypesGroup: Object.values(data),
      workTypes: workTypes.map((t) => getWorkTypeOption(t)),
      workTypesTranslate: WORK_TYPES_TRANSLATES
    };
  }

  getWorkTypesTranslations() {
    return WORK_TYPES_TRANSLATES;
  }
}
