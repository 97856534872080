import { TranslateService } from '@ngx-translate/core';

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { GlobalObjectService } from '@core/services/global-object.service';

import { MAIN_LANGUAGE } from '@common/shared/constants';
import { BrowserLanguageEnum, LanguageEnum } from '@common/shared/enums';
import { setCookie } from '@common/shared/helpers';

import { LANG_KEY } from '@client/shared/constants';

// https://indepth.dev/posts/1047/implementing-multi-language-angular-applications-rendered-on-server
// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular-app-with-ngx-translate
@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private regExpCache: { [locale: string]: RegExp } = {};
  langList = Object.values(LanguageEnum);
  private locale$ = new BehaviorSubject<LanguageEnum>(null);
  _locale: LanguageEnum = null;
  set locale(value: LanguageEnum) {
    if (this.langList.includes(value)) {
      this._locale = value;
    } else {
      this._locale = MAIN_LANGUAGE;
    }
    this.locale$.next(this._locale);
  }

  get locale(): LanguageEnum {
    return this._locale;
  }

  constructor(
    readonly translateService: TranslateService,
    readonly router: Router,
    readonly location: Location,
    readonly globalObjectService: GlobalObjectService
  ) {}

  useLanguage(language: LanguageEnum): void {
    if (!language || language === this.locale) {
      return;
    }

    this.translateService.use(language);
    this.todoLanguageRedirect(language);
    this.setLangToCookies(language);

    this.locale = language;
  }

  private todoLanguageRedirect(lang: LanguageEnum) {
    const url = this.router.url
      .split('/')
      .filter(Boolean)
      .filter((u) => !this.langList.includes(u as any))
      .join('/');
    const redirectUrl = lang === MAIN_LANGUAGE ? `/${url}` : `/${lang}/${url}`;

    this.location.replaceState(redirectUrl);
  }

  private setLangToCookies(lang: LanguageEnum) {
    setCookie(LANG_KEY, String(lang));
  }

  setDefaultLanguage() {
    this.locale =
      this.globalObjectService.getServerCookies(LANG_KEY) ||
      this.findLocaleInUrl() ||
      this.getBrowserLanguage() ||
      MAIN_LANGUAGE;

    this.setLangToCookies(this.locale);
    this.translateService.setDefaultLang(this.locale);
    return this.locale;
  }

  findLocaleInUrl() {
    return this.globalObjectService
      .getSitePathname()
      .split('/')
      .filter(Boolean)
      .find((u) => this.langList.includes(u as any)) as LanguageEnum;
  }

  getLocale() {
    return this.locale$.asObservable();
  }

  getInstant(key: string | Array<string>, interpolateParams?: any) {
    if (!key) {
      return '';
    }
    return this.translateService.instant(key, interpolateParams);
  }

  updateLocaleUrl(url: string) {
    const path = this.locale === MAIN_LANGUAGE ? '' : `/${this.locale}`;
    if (path && !url.startsWith(`/${path}`)) {
      let redirect = '';
      if (this.regExpCache[this.locale]) {
        redirect = `${url.replace(this.regExpCache[this.locale], '')}`;
      } else {
        this.regExpCache[this.locale] = new RegExp(`^${path}(?=/|$)`, 'g');
        redirect = `${url.replace(this.regExpCache[this.locale], '')}`;
      }
      this.location.replaceState(`${path}${redirect === '/' ? '' : redirect}`);
    }
  }

  getBrowserLanguage() {
    let lang = null;
    try {
      lang = BrowserLanguageEnum[navigator?.language];
    } catch (e) {}
    return lang;
  }
}
