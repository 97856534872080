export enum AWSMediaPathEnum {
  UserAvatars = 'user-avatars',
  CompanyLogos = 'company-logos',
  ResumeFiles = 'resume-files',
  ChatFiles = 'chat-files',
  OfficePhotos = 'office-photos',
  CompanyBanners = 'company-banners',
  JobPlaceholders = 'job-placeholders',
  Posts = 'posts'
}
