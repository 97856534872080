import { PostTypeEnum } from '../enums';

export const POSTS_TYPE_PATHS = Object.freeze({
  [PostTypeEnum.Meetups]: 'get-together',
  [PostTypeEnum.Events]: 'events',
  [PostTypeEnum.Memes]: 'memes',
  [PostTypeEnum.UserNews]: 'posts',
  [PostTypeEnum.Startups]: 'startups',
  [PostTypeEnum.CompanyNews]: 'company-news'
});
