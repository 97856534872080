import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import { DOCUMENT, isPlatformBrowser, PlatformLocation } from '@angular/common';
import {
  Injectable,
  Inject,
  PLATFORM_ID,
  Optional,
  InjectionToken
} from '@angular/core';

import { LanguageEnum } from '@common/shared/enums';
import { getCookie } from '@common/shared/helpers';

import { LANG_KEY } from '@client/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class GlobalObjectService {
  constructor(
    readonly platformLocation: PlatformLocation,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() @Inject(REQUEST) protected request: Request
  ) {}

  isPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  getWindow(): Window | null {
    return this.getDocument()?.defaultView || null;
  }

  getDocument(): Document | null {
    return this.doc || null;
  }

  getLocation(): Location | null {
    return this.getDocument()?.location || null;
  }

  createElement(tag: string): HTMLElement {
    return this.getDocument()?.createElement(tag) || null;
  }

  isServerRequest() {
    return this.request && !this.isPlatformBrowser();
  }

  getCurrentUrl() {
    if (this.isPlatformBrowser()) {
      return this.getWindow()?.location?.href || '';
    } else {
      const protocol = this.request.protocol;
      const host = this.request.get('host');
      const pathname = this.platformLocation.pathname;
      return `${protocol}://${host}${pathname}`;
    }
  }

  getOrigin() {
    if (this.isPlatformBrowser()) {
      return this.getWindow()?.location?.origin || '';
    } else {
      const protocol = this.request.protocol;
      const host = this.request.get('host');
      return `${protocol}://${host}`;
    }
  }

  getSitePathname() {
    return this.isPlatformBrowser()
      ? this.getWindow()?.location?.pathname
      : this.platformLocation.pathname;
  }

  isDesktop() {
    if (this.isPlatformBrowser()) {
      const ua = navigator?.userAgent;
      const mobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          ua
        );
      const tablet = /Tablet|iPad/i.test(ua);
      return !mobile && !tablet;
    }
    return true;
  }

  buildUrl(url = '') {
    let lang = '';
    if (this.isServerRequest()) {
      lang =
        Object.values(LanguageEnum).find((l) =>
          this.request.originalUrl.startsWith(`/${l}`)
        ) || '';
    } else {
      lang = this.getServerCookies(LANG_KEY);
      if (lang === LanguageEnum.English) {
        lang = '';
      }
    }
    return (
      (this.isServerRequest() ? this.getOrigin() : this.getLocation()?.origin) +
      (!lang || url.includes('assets') ? '' : `/${lang}`) +
      (url ? `/${url}` : '')
    );
  }

  getServerCookies(key = null) {
    const cookies = this.isServerRequest() && this.request.headers.cookie;
    if (key) {
      return getCookie(key, cookies);
    }
    return cookies;
  }

  getStorageData(
    key: string,
    type: 'sessionStorage' | 'localStorage' = 'localStorage'
  ) {
    return this.getDocument()?.defaultView?.[type]?.getItem(key) || null;
  }

  setStorageData(
    key: string,
    value: string,
    type: 'sessionStorage' | 'localStorage' = 'localStorage'
  ) {
    this.getDocument()?.defaultView?.[type]?.setItem(key, value);
  }

  removeStorageData(
    key: string,
    type: 'sessionStorage' | 'localStorage' = 'localStorage'
  ) {
    this.getDocument()?.defaultView?.[type]?.removeItem(key);
  }

  scrollTop(top = 0) {
    this.getWindow()?.scrollTo({ top, behavior: 'smooth' });
  }

  initScrollTop() {
    this.getWindow()?.scrollTo({ top: 0 });
  }
}
