import { IResume, IResumeResponse } from '@modules/resumes/interfaces';

import { LANGUAGE_LEVEL } from '@common/shared/constants';
import {
  ActiveStatusEnum,
  AWSMediaPathEnum,
  WorkTypeGroupEnum
} from '@common/shared/enums';

import { environment } from '@env';

import { userFromResponse } from './user-object';

const CURRENT_YEAR = new Date().getFullYear();
export function resumeFromResponse(resume: IResumeResponse): IResume {
  const user = resume?.user;
  return resume
    ? {
        ...resume,
        locked: resume?.user?.status === ActiveStatusEnum.Inactive,
        files: (resume?.files || []).map((f) => {
          f.path = `${environment.serverUrl}/storage/${AWSMediaPathEnum.ResumeFiles}/${f.filename}`;
          return f;
        }),
        experience: resume?.experienceFrom
          ? CURRENT_YEAR - resume.experienceFrom
          : null,
        user: userFromResponse(user),
        englishLabel: resume.english
          ? LANGUAGE_LEVEL.find((el) => el.id === resume.english)?.value
          : '',
        blockedCompanies: resume?.blockedCompanies
          ? resume?.blockedCompanies.map((c) => ({
              name: c.name,
              id: c.id
            }))
          : [],
        domainsValue: resume?.domains?.map((d) => d.value).join(', '),
        languagesValue: resume?.languages?.map((d) => d.value).join(', '),
        workTypesMap: resume?.workTypes?.length
          ? resume?.workTypes?.reduce(
              (acc, el) => {
                if (acc[el.group]) {
                  acc[el.group].push(el);
                }
                return acc;
              },
              {
                [WorkTypeGroupEnum.Time]: [],
                [WorkTypeGroupEnum.Place]: []
              }
            )
          : {
              [WorkTypeGroupEnum.Time]: [],
              [WorkTypeGroupEnum.Place]: []
            }
      }
    : null;
}
