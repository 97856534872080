export const WEB_PUSH_PAUSE_COOKIE = 'a_web_push';
export const WEB_PUSH_CONFIRMED_COOKIE = 'k_web_push_conf';
export const AUTH_REDIRECT = 'auth_redirect';
export const MAP_JOB_ZOOM_KEY = 'map_job_zoom';
export const MAP_JOB_CENTER_KEY = 'map_job_center';
export const MAP_OFFICE_ZOOM_KEY = 'map_office_zoom';
export const MAP_OFFICE_CENTER_KEY = 'map_office_center';
export const LANG_KEY = 'kup_lang';
export const ADMIN_TOKEN = 'kupno_admin_token';
export const BACK_URL = 'kupno_back_url';
export const FIRST_VISIT = 'first-visit';
export const HIDE_RESUME_WITH_REQ_INV_DEFAULT_D = 60;
export const HIDE_REQ_KEY = 'hide_requested';
export const HIDE_INV_KEY = 'hide_invited';
export const HIDE_INV_REQ_KEY = 'hide_inv_req';
export const HIDE_FAVORITE_KEY = 'hide_favorite';
export const HIDE_VIEWED_KEY = 'hide_viewed';
export const TOKEN_DURATION_D = 1;
export const UTM_TRACK_COOKIE_KEY = 'kup_utm_track';
export const UTM_TRACK_PARAM = 'k_d';
export const NO_LOADER_PARAM = 'no_loader';
export const TIME_STAMP_PARAM = '_ts';
export const REQ_ID_PREFIX = 'req-';
export const INV_ID_PREFIX = 'inv-';
export const DARK_THEME = 'kup_theme_dark';
export const NEW_VERSION_RELOAD = 'kup_new_version';
export const MOBILE_WIDTH = 767;
export const TABLET_WIDTH = 1023;
