import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AgentsFactory } from '@modules/companies/services/agents.factory';

import { AuthService, IUserEntity } from '@core/auth';
import { GlobalObjectService } from '@core/services';

import { AgentRoleEnum } from '@common/shared/enums';

import { BaseHttpService } from '@client/shared/abstracts';

import { IAgentRequest, IAgentResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AgentsService extends BaseHttpService {
  constructor(
    readonly factory: AgentsFactory,
    readonly authService: AuthService,
    readonly globalObjectService: GlobalObjectService,
    readonly router: Router
  ) {
    super('agents');
  }

  getAgents() {
    return this.get<IAgentResponse[]>({}, '').pipe(
      map(({ content }) => content.map((o) => this.factory.fromResponse(o)))
    );
  }

  getAgentsSelect() {
    return this.get<IUserEntity[]>({}, 'company-users').pipe(
      map(({ content }) =>
        content.map((a) => ({
          id: a.id,
          value: `${a.firstName} ${a.lastName}`
        }))
      )
    );
  }

  getAgentInvite(token: string) {
    return this.get<IAgentResponse>({}, token).pipe(
      map(({ content }) => this.factory.fromResponse(content)),
      catchError((err) => {
        this.router
          .navigateByUrl('/')
          .then(() => this.globalObjectService.initScrollTop());
        return throwError(err);
      })
    );
  }

  changeAgentRole(role: AgentRoleEnum, id: number) {
    return this.patch({ role }, {}, `${id}`);
  }

  inviteAgent(agent: IAgentRequest) {
    const request = this.factory.toRequest(agent);

    return this.post<IAgentRequest, IAgentResponse>(request).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  reinvite(id: number) {
    return this.post(null, {}, id);
  }

  deleteAgent(id: number) {
    return this.delete({}, id);
  }
}
