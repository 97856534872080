import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ClosePostReasonEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

export const CLOSE_POST_REASONS: ISelectOption[] = [
  {
    id: ClosePostReasonEnum.Completed,
    value: _('closePostReason.completed')
  },
  {
    id: ClosePostReasonEnum.Cancelled,
    value: _('closePostReason.cancelled')
  },
  {
    id: ClosePostReasonEnum.Other,
    value: _('closePostReason.other')
  }
];
