import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { PostMemeNavEnum } from '@client/shared/enums';

export const POST_MEME_NAV = Object.freeze([
  {
    id: PostMemeNavEnum.Posts,
    value: _('postsNavigation.posts')
  },
  {
    id: PostMemeNavEnum.Memes,
    value: _('postsNavigation.memes')
  }
]);
