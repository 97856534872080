import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { PostStartupStateEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

export const POST_STARTUP_STATE: ISelectOption[] = [
  {
    id: PostStartupStateEnum.Concept,
    value: _('postStartupState.concept')
  },
  {
    id: PostStartupStateEnum.Prototype,
    value: _('postStartupState.prototype')
  },
  {
    id: PostStartupStateEnum.MVP,
    value: _('postStartupState.MVP')
  },
  {
    id: PostStartupStateEnum.Launch,
    value: _('postStartupState.launch')
  },
  {
    id: PostStartupStateEnum.EarlyGrowth,
    value: _('postStartupState.earlyGrowth')
  },
  {
    id: PostStartupStateEnum.Scaling,
    value: _('postStartupState.scaling')
  },
  {
    id: PostStartupStateEnum.SustainedGrowth,
    value: _('postStartupState.sustainedGrowth')
  }
];
