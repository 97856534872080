import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { PostTypeEnum } from '@common/shared/enums';
import { ReadyToWorkEnum } from '@common/shared/enums/ready-to-work.enum';

export const HUB_CATEGORIES = Object.freeze([
  {
    id: PostTypeEnum.UserNews,
    value: _('hub.category.title.userNews')
  },
  {
    id: PostTypeEnum.Events,
    value: _('hub.category.title.events')
  },
  {
    id: PostTypeEnum.CompanyNews,
    value: _('hub.category.title.companyNews')
  },
  {
    id: PostTypeEnum.Startups,
    value: _('hub.category.title.startups')
  },
  {
    id: PostTypeEnum.Meetups,
    value: _('hub.category.title.meetups')
  },
  {
    id: PostTypeEnum.Memes,
    value: _('hub.category.title.memes')
  }
]);
