import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';

import { AuthService } from '@core/auth';
import {
  GlobalObjectService,
  LocaleService,
  NotificationService
} from '@core/services';

import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  lastErrorMessage = '';
  constructor(
    readonly notificationService: NotificationService,
    readonly globalObjectService: GlobalObjectService,
    readonly authService: AuthService,
    readonly zone: NgZone,
    readonly localeService: LocaleService
  ) {}

  // DOC https://medium.com/angular-in-depth/expecting-the-unexpected-best-practices-for-error-handling-in-angular-21c3662ef9e4
  handleError(error: HttpErrorResponse | Error) {
    if (this.isChunkLoadError(error)) {
      this.zone.run(() => {
        this.globalObjectService.getWindow()?.location?.reload();
      });
    } else {
      if (error instanceof HttpErrorResponse) {
        // Handle server-side error
        if (error.status !== 0) {
          this.handleServerError(error);
        }
      } else if (this.globalObjectService.isPlatformBrowser()) {
        if (navigator?.onLine) {
          this.handleClientError(error);
        } else {
          // Handle network error
          this.handleNetworkError();
        }
      }

      this.logError(error);

      // For unknown errors, you might want to reload the app or redirect
      if (this.isUnknownError(error)) {
        this.handleUnknownError();
      }
    }
  }

  private handleClientError(error: Error) {
    if (!environment.production) {
      this.notificationService.showError(error?.message?.trim());
    }
    this.sendErrorToServer(error);
  }

  private handleServerError(error: HttpErrorResponse) {
    this.notificationService.showError(error);
  }

  private handleNetworkError() {
    this.notificationService.showError(_('error.general.offline'));
  }

  private handleUnknownError() {
    this.notificationService.showError('');
  }

  private isUnknownError(error: any): boolean {
    return !(error instanceof Error || error instanceof HttpErrorResponse);
  }

  private logError(error: any) {
    console.error(error);
  }

  private isChunkLoadError(error: any): boolean {
    return (
      /Loading chunk [\d]+ failed/.test(error?.message) ||
      error?.message?.includes('ChunkLoadError')
    );
  }

  sendErrorToServer(error) {
    try {
      if (
        !this.globalObjectService.isPlatformBrowser() ||
        this.lastErrorMessage === error?.message ||
        error?.message?.includes('assets/v1/icons')
      ) {
        return;
      }

      this.lastErrorMessage = error?.message || '';
      const errorData = {
        errorMessage: error?.message || '',
        stackTrace: error?.stack || '',
        pageUrl: window?.location?.href || '',
        browserType: navigator?.userAgent || '',
        browserVersion: navigator?.appVersion || '',
        operatingSystem: navigator?.platform || '',
        screenSize: `${window?.innerWidth}x${window.innerHeight}` || '',
        browserLanguage: navigator?.language || '',
        userId: this.authService.getProfileValue()?.id || null
      };

      fetch(`${environment.serverUrl}/logger/client`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(errorData)
      });
    } catch (e) {
      console.error('Error logger', e);
    }
  }
}
