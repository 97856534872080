import { Injectable } from '@angular/core';

import {
  companyFromResponse,
  resumeFromResponse,
  userFromResponse
} from '@client/shared/utils';

import { IProfile, IProfileResponse } from '../auth.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthFactory {
  constructor() {}
  fromResponse(response: IProfileResponse): IProfile {
    return {
      ...userFromResponse(response),
      resumes: (response?.resumes || [])
        .sort(
          (a, b) =>
            new Date(a.created).getTime() - new Date(b.created).getTime()
        )
        .sort((r) => (r.main ? -1 : 1))
        .map((r) => resumeFromResponse(r)),
      hasResume: !!(response?.resumes || [])?.[0]?.position,
      multiResume: response?.resumes?.length > 1,
      fullName: `${response.firstName} ${response.lastName}`,
      mobileName: `${response.firstName.charAt(0)}${response.lastName.charAt(
        0
      )}`,
      id: response.id,
      firstName: response.firstName,
      lastName: response.lastName,
      isAdmin: response.isAdmin,
      mailingRecommendation: response.mailingRecommendation,
      mailingAppUpdate: response.mailingAppUpdate,
      email: response.username,
      integrations: response.integrations,
      company: response.company ? companyFromResponse(response.company) : null,
      exist: true,
      sites: response.sites,
      location: response.location,
      publisher: response.publisher
    };
  }
}
