import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, Router } from '@angular/router';

import { IJob } from '@modules/jobs/interfaces';
import { IResume } from '@modules/resumes/interfaces';

import { GlobalObjectService } from '@core/services/global-object.service';

import { EXPERIENCES_MAP } from '@common/shared/constants';
import { JobViewTypeEnum } from '@common/shared/enums';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor(
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly globalObjectService: GlobalObjectService,
    readonly location: Location
  ) {}

  changeQueryParam(query = {}, queryParamsHandling: QueryParamsHandling = '') {
    const data = this.filterEmptyQueryData(query);

    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          ...data
        }
      })
      .toString();

    this.location.replaceState(url);
  }

  filterEmptyQueryData(query: any) {
    for (const key in query) {
      if (
        query.hasOwnProperty(key) &&
        (query[key] === '' ||
          query[key] === null ||
          query[key] === undefined ||
          query[key] === false ||
          (Array.isArray(query[key]) && !query[key]?.length))
      ) {
        query[key] = null;
      } else {
        if (typeof query[key] === 'object' && Array.isArray(query[key])) {
          query[key] = query[key].join(',');
        }
      }
    }

    return query;
  }

  getQueryParams() {
    return Object.fromEntries(
      new URLSearchParams(
        this.globalObjectService.getWindow()?.location?.search || {}
      )
    );
  }

  setJobViewParams(from: JobViewTypeEnum) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        j_view: from
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  async createOgLogo(logo: string) {
    return await new Promise((resolve, reject) => {
      const image = new Image();
      image.src = logo as string;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = 202;
        canvas.height = 202;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const newWidth = image.width / 2;
        const newHeight = image.height / 2;
        const xOffset = (canvas.width - newWidth) / 2;
        const yOffset = 50;

        ctx.drawImage(image, xOffset, yOffset, newWidth, newHeight);
        resolve(canvas.toDataURL());
      };
      image.onerror = () => {
        resolve(logo);
      };
    });
  }
}
