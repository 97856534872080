import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IQuery } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { IFile } from '@client/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class UploaderService extends BaseHttpService {
  constructor() {
    super('storage');
  }

  uploadResumeFiles(files: File[], resumeId: string) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    return this.post<FormData, IFile[]>(
      formData,
      {},
      `resume-files/${resumeId}`
    ).pipe(map(({ content }) => content));
  }

  uploadMessageFiles(files: File[], chatId: number) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    return this.post<FormData, IFile[]>(
      formData,
      {},
      `message-files/${chatId}`
    ).pipe(map(({ content }) => content));
  }

  uploadPostFiles(files: File[], query: IQuery = {}) {
    // ?main=1 will create sm__
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    return this.post<FormData, string>(formData, query, `post-files`).pipe(
      map(({ content }) => content)
    );
  }

  uploadPhotos(files: File[], officeId: number) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    return this.post<FormData, IFile[]>(
      formData,
      {},
      `office/${officeId}`
    ).pipe(map(({ content }) => content));
  }

  uploadCompanyBanner(file: File, companyId: number) {
    const formData = new FormData();
    formData.append('banner', file);
    return this.post<FormData, string>(
      formData,
      {},
      `company/${companyId}/banner`
    ).pipe(map(({ content }) => content));
  }
}
