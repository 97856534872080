import { TransferHttpCacheModule } from '@nguniversal/common';
import {
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isToday from 'dayjs/plugin/isToday';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { registerLocaleData } from '@angular/common';
import {
  HttpClientModule,
  HttpClientJsonpModule,
  HttpClient
} from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localeUk from '@angular/common/locales/uk';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { CoreModule } from '@core/core.module';
import { CustomTranslationHandler, LocaleService } from '@core/services';

import { LocalizationEnum } from '@common/shared/enums';

import { environment } from '@env';

import { AppComponent } from './app.component';
import { RoutingModule } from './routing';

registerLocaleData(localeUk);
registerLocaleData(localeEN);

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore);

const config: SocketIoConfig = { url: `${environment.serverUrl}`, options: {} };

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    RoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserAnimationsModule,
    HttpClientJsonpModule,
    SocketIoModule.forRoot(config),
    TransferHttpCacheModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomTranslationHandler
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    CoreModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatTooltipModule,
    MatMenuModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    RecaptchaV3Module
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (localeService: { locale: string }) =>
        LocalizationEnum[localeService.locale] // locale for date pipe
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.googleRecaptchaKey
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
