import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { fromEvent } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { LANG_KEY } from '@client/shared/constants';

import { GlobalObjectService } from './global-object.service';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private linkClick = false;
  private scrollPositions = new Map<string, number>();
  private currentUrl: string = '';
  constructor(
    readonly router: Router,
    readonly globalObjectService: GlobalObjectService
  ) {
    this.listenLinkClick();
    this.detectPageScroll();
  }

  detectPageScroll() {
    this.router.events
      .pipe(
        tap((event: any) => {
          if (event instanceof NavigationStart) {
            this.scrollPositions.set(this.currentUrl, window.scrollY);
          }
          if (event instanceof NavigationEnd) {
            this.currentUrl = event.urlAfterRedirects.replace(
              `/${this.globalObjectService.getServerCookies(LANG_KEY)}`,
              ''
            );
            this.listenScrollUpdate();
          }
        })
      )
      .subscribe();
  }

  checkScroll() {
    const savedPosition = this.scrollPositions.get(this.currentUrl);
    if (savedPosition !== undefined) {
      this.globalObjectService.getWindow()?.scrollTo(0, savedPosition);
    }
  }

  listenScrollUpdate() {
    if (this.linkClick) {
      this.linkClick = false;
      this.scrollPositions.delete(this.currentUrl);
      this.globalObjectService.initScrollTop();
    }
  }

  listenLinkClick() {
    fromEvent(this.globalObjectService.getDocument(), 'click', {
      capture: true
    })
      .pipe(
        filter((event: Event) => {
          const target = event.target as HTMLElement;
          const a = target?.closest('a');
          return (
            (target.tagName === 'A' || !!a) &&
            !a?.getAttribute('data-ignore-scroll')
          );
        }),
        tap(() => {
          this.linkClick = true;
        })
      )
      .subscribe();
  }
}
