import { IUserEntity } from '@core/auth';

import { COUNTRIES } from '@client/shared/constants';
import { getAvatarPath } from '@client/shared/utils/avatar-path';

export function userFromResponse(user: IUserEntity): IUserEntity {
  return user
    ? {
        ...user,
        avatar: getAvatarPath(user.avatar),
        locationValue: user?.city
          ? `${user.city}, ${COUNTRIES[user.countryCode]}`
          : COUNTRIES[user?.countryCode] || ''
      }
    : null;
}
